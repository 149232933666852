import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Observable, throwError } from 'rxjs'
import { tap } from 'rxjs/operators'
import { ToastService } from '../data-access/toast/toast.service'
import { HttpCode } from '@models/common.model';

@Injectable()
export class ErrorResponseInterceptor implements HttpInterceptor {
  constructor(
    private toastService: ToastService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (res: any) => {},
        (error: any) => {
          switch (error?.status) {
            case HttpCode?.BadRequest:
              if (error.error.error === 'MERCHANT_TABLE_QR_OPEN_BILL_PAID') {
                console.log(error.error.error)
                return
              }
              break
            case HttpCode?.NotFound:
              if (error.error.error === 'MERCHANT_NOT_FOUND') {
                this.router.navigate(['/e/404'])
                this.toastService.broadcast('SHOW_TOAST', error?.error?.error)
              } else if (
                error.error.error === 'MERCHANT_TABLE_MANAGEMENT_NEED_ACTIVATE'
              ) {
                this.router.navigate([
                  '/e/table-management-requires-activation'
                ])
              }
              break

            case 422:
              if (error.error.error === 'ORDER_CART_EMPTY') {
                return
              }

              break

            case HttpCode?.Forbidden:
              break

            case HttpCode?.InternalServerError:
              break

            case HttpCode?.Unauthorized:
              break

            case HttpCode?.ServerDown:
              break
            default:
              this.toastService.broadcast('SHOW_TOAST', error?.error?.error)
              return true
          }
          return throwError(() => new Error(error?.error?.error))
        }
      )
    )
  }
}
