import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { DataStorageService } from '@data-access/data-storage/data-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'byod-idequeque';

  constructor(
    private _dataStorageService: DataStorageService,
    private readonly renderer: Renderer2,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  ngOnInit(): void {
    this.setQueueUI();
  }

  setQueueUI() {
    const appearance = this._dataStorageService.getData('appearance');
    const storePrimaryColor = appearance?.primary ?? '#00489A';
    const storeSecondaryColor = appearance?.secondary ?? '#F6F9FF';
    const storeTextPrimaryColor = appearance.text ?? '#FFFFFF';
    const storeTextSecondaryColor = appearance.textSecondary ?? '#404040';
    const storeBackground = appearance?.background ?? '#FFFFFF';

    this.renderer.setStyle(
      this.document.documentElement,
      '--store-primary-color',
      storePrimaryColor,
      2
    );
    this.renderer.setStyle(
      this.document.documentElement,
      '--store-secondary-color',
      storeSecondaryColor,
      2
    );
    this.renderer.setStyle(
      this.document.documentElement,
      '--store-text-primary',
      storeTextPrimaryColor,
      2
    );
    this.renderer.setStyle(
      this.document.documentElement,
      '--store-text-secondary-color',
      storeTextSecondaryColor,
      2
    );
    this.renderer.setStyle(
      this.document.documentElement,
      '--store-background-color',
      storeBackground,
      2
    );
  }
}
