import { TranslateService } from '@ngx-translate/core'
import { DataStorageService } from '../data-storage/data-storage.service';

export class InitialLanguageLoader {
  constructor(translate: TranslateService, viewService: DataStorageService) {
    return () => {
      let langCode = viewService.getLocalStorage('langCode') ?? 'en-US'

      const languages = ['en-US', 'id-ID', 'km-KH', 'zh-TW', 'zh-CN']

      if (!languages.includes(langCode)) {
        langCode = 'en-US'
        viewService.setLocalStorage('langCode', langCode)
      }

      translate.setDefaultLang(langCode)
      return translate.use(langCode).toPromise()
    }
  }
}
