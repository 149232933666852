import {
  APP_INITIALIZER,
  ErrorHandler,
  NgModule,
  TransferState,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LogService } from '@data-access/log/log.service';
import { ErrorResponseInterceptor } from '@interceptor/error-handler.interceptor';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { ToastModule } from '@ui/toast/toast.module';
import { LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DataStorageService } from './shared/data-access/data-storage/data-storage.service';
import { ToastService } from './shared/data-access/toast/toast.service';
import { InitialLanguageLoader } from './shared/data-access/translate/initial-language-loader.service';
import { TranslateBrowserLoader } from './shared/data-access/translate/translate-browser-loader.service';
import { makeId } from './shared/models/common.model';
import { NgxSpinnerModule } from 'ngx-spinner';

export function exportTranslateStaticLoader(
  http: HttpClient,
  transferState: TransferState
) {
  return new TranslateBrowserLoader(
    '/assets/i18n/',
    `.json?${makeId(5)}`,
    transferState,
    http
  );
}

export function appInitializerFactory(
  translate: TranslateService,
  dataStorageService: DataStorageService
) {
  return new InitialLanguageLoader(translate, dataStorageService);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastModule,
    TranslateModule.forRoot({
      useDefaultLang: true,
      defaultLanguage: 'en-US',
      loader: {
        provide: TranslateLoader,
        useFactory: exportTranslateStaticLoader,
        deps: [HttpClient, TransferState],
      },
    }),
    NgxSpinnerModule.forRoot({ type: 'ball-spin-clockwise-fade' }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, DataStorageService],
      multi: true,
    },
    ToastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorResponseInterceptor,
      multi: true,
    },
    { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },
    {
      provide: ErrorHandler,
      useClass: LogService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
